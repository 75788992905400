import React from "react";
import Prepare from "../components/Faq/prepare";
import WhereToBuyUs from "../components/WhereToBuyUs/wheretobuyus";
export const faqData = [
    {
        question: "faqData.question1",
        answer: "faqData.answer1",
        component: "",
    },
    {
        question: "faqData.question2",
        answer: "faqData.answer2",
        component: "",
    },
    {
        question: "faqData.question3",
        answer: "faqData.answer3",
        component: "",
    },
    {
        question: "faqData.question4",
        answer: "faqData.answer4",
        component: "",
    },
    {
        question: "faqData.question5",
        answer: "faqData.answer5",
        component: "",
    },
    {
        question: "faqData.question6",
        answer: "faqData.answer6",
        component: "",
    },
    {
        question: "faqData.question7",
        answer: "faqData.answer7",
        component: <WhereToBuyUs heading={false} />,
    },
    {
        question: "faqData.question8",
        answer: "faqData.answer8",
        component: "",
    },
    {
        question: "faqData.question9",
        answer: "faqData.answer9",
        component: "",
    },
    {
        question: "faqData.question10",
        answer: "faqData.answer10",
        component: "",
    },
    {
        question: "faqData.question11",
        answer: "faqData.answer11",
        component: "",
    },
    {
        question: "faqData.question12",
        answer: "",
        component: <Prepare />,
    },
];
