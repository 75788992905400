import React from "react";
import { productDetailData } from "../../data/productDetail";
import "./faqs.scss";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const Prepare = () => {
    const arr = ["I", "II", "III", "IV", "V", "VI", "VII","VIII","IX"];
    const { t } = useTranslation();

    return (
        <div className="prepare12">
            <div className="prepare12Grid">
                <div className="ssprepare">
                    <h2>SNACK SERIES</h2>
                    <dl>
                        {productDetailData.map((item, i) => (
                            <div hidden={i > 2 ? true : false}>
                                <dt>
                                    {arr[i]}) {t("productDetail.prefix")} {t(item.title).replace("<br/>", "")}
                                    <br />
                                </dt>
                                <dd>
                                    <h6 dangerouslySetInnerHTML={{ __html: t(item.method1Name) }}></h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method1desc) }}></p>
                                    <h6>{t(item.method2Name)}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method2desc) }}></p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="tsprepare">
                    <h2>THAI TASTE SERIES</h2>
                    <dl>
                        {productDetailData.map((item, i) => (
                            <div hidden={(i < 3 || i > 6) ? true : false}>
                                <dt>
                                    {arr[i]}) {t("productDetail.prefix")} {t(item.title).replace("<br/>", "")}
                                </dt>
                                <dd>
                                    <h6>{t(item.method1Name)}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method1desc) }}></p>
                                    <h6>{t(item.method2Name)}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method2desc) }}></p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="tsprepare">
                    <h2>J SERIES</h2>
                    <dl>
                        {productDetailData.map((item, i) => (
                            <div hidden={i < 7 ? true : false}>
                                <dt>
                                    {arr[i]}) {t("productDetail.prefix")} {t(item.title).replace("<br/>", "")}
                                </dt>
                                <dd>
                                    <h6>{t(item.method1Name)}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method1desc) }}></p>
                                    <h6>{t(item.method2Name)}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method2desc) }}></p>
                                    <h6>{t(item.method3Name)}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: t(item.method3desc) }}></p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default (Prepare);
