import React from "react";
import Layout from "../components/Layout";
import { Accordion } from "react-bootstrap";
import { faqData } from "../data/faqData";
import watermark from "../img/leafWaterMark.png";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

import "../scss/faq.scss";
import Helmet from "react-helmet";

const Faq = (props) => {
    const { t } = useTranslation();    

    return (
        <Layout title="FAQ" description="Curiousity is healthy. Get all your questions about FIRST PRIDE plant-based protein answered here.">
            <Helmet> 
                <link rel="alternate" href="https://www.firstpridethailand.com/faq/" hreflang="th-th" />
                <link rel="alternate" href="https://www.firstpride.sg/faq" hreflang="en-sg" />
            </Helmet>
                <div className="faq">
                    <div className="faqBanner">
                        <div className="faqBannerContent">
                            <h1>{t("faqPage.title1")}</h1>
                            <h5>{t("faqPage.subtitle1")}</h5>
                        </div>
                    </div>
                    <div className="faqContent">
                        <Accordion flush>
                            {faqData.map((data, i) => (
                                <Accordion.Item eventKey={i}>
                                    <Accordion.Header>{t(data.question)}</Accordion.Header>
                                    <Accordion.Body style={{ whiteSpace: "pre-wrap" }} className="p-regular">
                                        <p className="p-regular" dangerouslySetInnerHTML={{ __html: t(data.answer) }}/>
                                        {data.component}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
        </Layout>
    );
};

export default (Faq);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer", "faq", "products", "whereToBuy"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;